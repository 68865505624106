// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as hddOrderStatusFragments } from "../../application/apps/HDDPressApp/components/HDDOrderStatus/graph";

const markOrderAsHighDefinitionDigitalTrimmed: any = graphql(
  gql`
    mutation ($orderId: ID!) {
      markOrderAsHighDefinitionDigitalTrimmed(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...HDDOrderStatus_order
          orderProductionJobEmbroiderySplit
        }
        succeeded
      }
    }
    ${hddOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsHighDefinitionDigitalTrimmed"
  }
);

export default markOrderAsHighDefinitionDigitalTrimmed;
