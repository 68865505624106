import { fragments as customizationAreaArtworkListFragments } from "../../../CustomizationAreaArtworkList/graph";
import { filter } from "graphql-anywhere";
import CustomizationAreaArtworkList from "../../../CustomizationAreaArtworkList";
import React from "react";

import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// TODO: Method
const CustomizationArea = ({
  customizationArea: {
    customerArtwork,
    quantity,
    width,
    height,
    precut,
    gangSheet,
    notesCollection
  }
}) => {
  return (
    <Paper>
      <CustomizationAreaArtworkList
        flipArtwork={false}
        proofs={filter(
          customizationAreaArtworkListFragments.proofs,
          customerArtwork.map(({ file }) => ({ ...file, originalFilename: "" }))
        )}
      />
      <Grid container columnSpacing={2} rowSpacing={1} style={{ padding: 5 }}>
        <Grid item xs={6}>
          Quantity: {quantity}
        </Grid>
        <Grid item xs={6}>
          Precut: {precut ? "True" : "False"}
        </Grid>
        <Grid item xs={6}>
          Gangsheet: {gangSheet ? "True" : "False"}
        </Grid>
        <Grid item xs={6}>
          Width: {width}"
        </Grid>
        <Grid item xs={6}>
          Height: {height}"
        </Grid>
        {!notesCollection || notesCollection.length === 0 ? null : (
          <Grid item xs={6}>
            <p style={{ margin: 0 }}>Art Notes:</p>
            <ul
              style={{
                paddingLeft: "10px"
              }}
            >
              {notesCollection.map((item, index) => {
                const { __typename } = item;

                if (__typename === "FormattedNote") {
                  const { author, note, createdAt } = item;
                  const timestamp = DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_MED
                  );

                  return (
                    <li key={index}>{`${author} (${timestamp}): ${note}`}</li>
                  );
                } else if (__typename === "UnformattedNote") {
                  const { note } = item;

                  return <li key={index}>{note}</li>;
                }

                return null;
              })}
            </ul>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const ProductionJobDetail = ({ productionJob }) => (
  <div style={{ padding: 10 }}>
    <Grid container spacing={2}>
      {productionJob.customizationAreas.map(customizationArea => (
        <Grid item xs={3} key={customizationArea.id}>
          <CustomizationArea customizationArea={customizationArea} />
        </Grid>
      ))}
    </Grid>
  </div>
);

export default ProductionJobDetail;
