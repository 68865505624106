import StandardProductionJobDetail from "./components/StandardProductionJobDetail";
import TransfersProductionJobDetail from "./components/TransfersProductionJobDetail";

const ProductionJobDetail = props => {
  const {
    productionJob: { customizationAreas }
  } = props;
  const methods = customizationAreas.map(({ method }) => method);
  if (
    methods.length > 0 &&
    methods.length ===
      methods.filter(method => method.startsWith("TRANSFER_")).length
  ) {
    return <TransfersProductionJobDetail {...props} />;
  }
  return <StandardProductionJobDetail {...props} />;
};

export default ProductionJobDetail;
