// @flow
import { fragments as automatedNotesWrapperFragments } from "../../../../../../components/AutomatedNotesWrapper/graph";
import { fragments as currentViewersFragments } from "../../../../../../components/CurrentViewers/graph";
import { fragments as dtgOrderStatusFragments } from "../../DTGOrderStatus/graph";
import { gql } from "@apollo/client";
import { fragments as notesWrapperFragments } from "../../../../../../components/NotesWrapper/graph";
import { fragments as orderStatusHeaderFragments } from "../../../../../../components/OrderStatusHeader/graph";
import { fragments as productionJobDetailFragments } from "../../../../../../components/ProductionJobDetail/graph";
import { fragments as productionJobListFragments } from "../../../../../../components/ProductionJobList/graph";

export default gql`
  query DTGOrderDetail($orderId: ID!) {
    viewer {
      ...CurrentViewers_viewer
    }
    order: node(id: $orderId) {
      ... on Order {
        ...AutomatedNotesWrapper_order
        ...DTGOrderStatus_order
        ...OrderStatusHeader_order
        ...NotesWrapper_order

        productionJobs {
          ...ProductionJobDetail_productionJob
          ...ProductionJobList_productionJobs
        }

        orderProductionJobEmbroiderySplit
      }
    }
  }
  ${automatedNotesWrapperFragments.order}
  ${currentViewersFragments.viewer}
  ${dtgOrderStatusFragments.order}
  ${orderStatusHeaderFragments.order}
  ${notesWrapperFragments.order}
  ${productionJobDetailFragments.productionJob}
  ${productionJobListFragments.productionJobs}
`;
