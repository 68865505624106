// @flow

import { gql } from "@apollo/client";
import { fragments as productMethodIconSetFragments } from "../../ProductionMethodIconSet/graph";
import { fragments as productStatusLabelFragments } from "../../ProductionStatusLabel/graph";

export default {
  order: gql`
    fragment OrderStatusHeader_order on Order {
      ...ProductionMethodIconSet_order
      ...ProductionStatusLabel_order
      dueBy {
        date
      }
      id
      isArtworkApproved
      isCanceled
      isResolution
      isInHouse
      orderNumber
      orderProductionJobEmbroiderySplit
      primaryCustomer {
        id
        insideBrandTagging
        name_orderStatusAppBar: name(format: FIRST_NAME_LAST_NAME)
        totalOrders
        businessUnit {
          id
          name
        }
        status
        emailAddress
        primaryPhoneNumber {
          formatted
        }
      }
      priority
      productionDate
      productionJobs {
        id
      }
      productionStationAssignments {
        id
        employee {
          id
          employee_orderStatusAppBar: name(format: FIRST_NAME_LAST_NAME)
        }
        productionStation {
          id
          name
        }
      }
      salesRepresentatives {
        id
        salesRepresentative_orderStatusAppBar: name(
          format: FIRST_NAME_LAST_NAME
        )
      }
      stockStatusLabel
      vendor {
        id
        internal
        organization {
          id
          name
        }
      }
    }
    ${productMethodIconSetFragments.order}
    ${productStatusLabelFragments.order}
  `
};
