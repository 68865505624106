import * as React from "react";
import { makeStyles } from "@mui/styles";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";

const useStyles = makeStyles(theme => ({
  warningSnackbarContainer: {
    margin: theme.spacing(2)
  },
  warningSnackbar: {
    backgroundColor: theme.palette.error.dark,
    maxWidth: "100%"
  },
  warningSnackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  }
}));

const OrderProductionJobEmbroiderySplitWarning = ({
  orderProductionJobEmbroiderySplit
}) => {
  const classes = useStyles();

  if (
    orderProductionJobEmbroiderySplit === "ALL" ||
    orderProductionJobEmbroiderySplit === "SOME"
  ) {
    const warningMessage =
      orderProductionJobEmbroiderySplit === "ALL"
        ? "Mixed Method: Send all films to Embroidery Facility"
        : "Mixed Method: Review order, split films for Embroidery Facility";

    return (
      <div className={classes.warningSnackbarContainer}>
        <SnackbarContent
          className={classes.warningSnackbar}
          message={
            <span className={classes.warningSnackbar}>
              <WarningIcon className={classes.warningSnackbarIcon} />
              {warningMessage}
            </span>
          }
        />
      </div>
    );
  }

  return null;
};

export default OrderProductionJobEmbroiderySplitWarning;
