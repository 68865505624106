// @flow

import * as React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { DateTime } from "luxon";
import {
  claimOrderProductionJobCustomizationArea,
  unclaimOrderProductionJobCustomizationArea
} from "../../graph";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import PendingStatusView from "../PendingStatusView/PendingStatusView";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Tooltip from "@mui/material/Tooltip";
import withSnackbar from "../withSnackbar";
import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +claimedByContainer: string,
    +claimedByLabel: string,
    +progressBarContainer: string
  |},
  +customizationAreaId: string
|};

const styles = theme => ({
  claimedByContainer: {
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1)
  },

  claimedByLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },

  progressBarContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0.5),
    width: "100%"
  }
});

type State = {|
  isInProgress: boolean
|};

const defaultState: State = {
  isInProgress: false
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaClaimDetails"),

  graphql(query),

  claimOrderProductionJobCustomizationArea,

  unclaimOrderProductionJobCustomizationArea,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(() => defaultState, {
    setIsInProgress: () => (isInProgress: boolean) => ({
      isInProgress
    })
  }),

  withHandlers({
    handleClaimButtonClick: ({
      claimOrderProductionJobCustomizationArea,
      customizationAreaId,
      data,
      setIsInProgress,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => {
      setIsInProgress(true);

      claimOrderProductionJobCustomizationArea({
        variables: {
          orderProductionJobCustomizationAreaId: customizationAreaId
        }
      })
        .then(
          ({
            data: {
              claimOrderProductionJobCustomizationArea: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`Customization area has been claimed.`);
              data.refetch();
            } else {
              showErrorSnackbar(
                `Something went wrong. Customization Area ${errors.orderProductionJobCustomizationAreaId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsInProgress(false));
    },

    handleUnclaimButtonClick: ({
      customizationAreaId,
      data,
      setIsInProgress,
      showErrorSnackbar,
      showSuccessSnackbar,
      unclaimOrderProductionJobCustomizationArea
    }) => {
      setIsInProgress(true);

      unclaimOrderProductionJobCustomizationArea({
        variables: {
          orderProductionJobCustomizationAreaId: customizationAreaId
        }
      })
        .then(
          ({
            data: {
              unclaimOrderProductionJobCustomizationArea: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`Customization area has been unclaimed.`);
              data.refetch();
            } else {
              showErrorSnackbar(
                `Something went wrong. Customization Area ${errors.orderProductionJobCustomizationAreaId.join(", ")}`
              );
            }
          }
        )
        .finally(() => setIsInProgress(false));
    }
  })
);

const CustomizationAreaClaimDetails = ({
  classes,
  data,
  handleClaimButtonClick,
  handleUnclaimButtonClick,
  isInProgress,
  notesCollection
}) => {
  const notesCollectionComponent =
    notesCollection && notesCollection.length > 0 ? (
      <NotesTooltipTitle notesCollection={notesCollection} />
    ) : null;

  if (!data) {
    return <PendingStatusView status="Waiting" />;
  } else if (data.loading) {
    return <PendingStatusView status="Loading" />;
  } else if (data.orderProductionJobCustomizationArea) {
    const {
      canViewerClaim,
      lastClaimedBy,
      orderProductionJobCustomizationAreaClaim
    } = data.orderProductionJobCustomizationArea;

    if (lastClaimedBy) {
      return (
        <div className={classes.claimedByContainer}>
          <Typography className={classes.claimedByLabel}>
            Last claimed By:
          </Typography>
          <Typography>{lastClaimedBy.name}</Typography>
        </div>
      );
    }

    const claimedEmployeeName = orderProductionJobCustomizationAreaClaim
      ? orderProductionJobCustomizationAreaClaim.employee.name
      : null;

    return (
      <div className={classes.claimedByContainer}>
        {isInProgress ? (
          <div className={classes.progressBarContainer}>
            <CircularProgress size={30} />
          </div>
        ) : claimedEmployeeName ? (
          <div>
            <Typography className={classes.claimedByText} variant="subtitle2">
              Claimed by: {claimedEmployeeName}
            </Typography>
            <Button
              color="primary"
              onClick={handleUnclaimButtonClick}
              variant="contained"
            >
              Unclaim
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            disabled={!canViewerClaim}
            onClick={handleClaimButtonClick}
            variant="contained"
          >
            Claim
          </Button>
        )}
        {notesCollectionComponent && (
          <Tooltip title={notesCollectionComponent} placement="top-start" arrow>
            <IconButton>
              <StickyNote2Icon color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return <div />;
  }
};

const NotesTooltipTitle = ({ notesCollection }) => (
  <div>
    <h3 style={{ margin: 0 }}>Art Notes:</h3>
    <ul
      style={{
        paddingLeft: "10px"
      }}
    >
      {notesCollection.map((item, index) => {
        const { __typename } = item;

        if (__typename === "FormattedNote") {
          const { author, note, createdAt } = item;
          const timestamp = DateTime.fromISO(createdAt).toLocaleString(
            DateTime.DATETIME_MED
          );

          return <li key={index}>{`${author} (${timestamp}): ${note}`}</li>;
        } else if (__typename === "UnformattedNote") {
          const { note } = item;

          return <li key={index}>{note}</li>;
        }

        return null;
      })}
    </ul>
  </div>
);

export default enhancer(CustomizationAreaClaimDetails);
