// @flow

import { DateTime } from "luxon";
import { compose, setDisplayName } from "recompose";
import { fragments as customizationAreaArtworkListFragments } from "../../../CustomizationAreaArtworkList/graph";
import { fragments as designProofListFragments } from "../../../DesignProofList/graph";
import { filter } from "graphql-anywhere";
import { getIconForProductionJobMethod } from "../../../../helpers/getIconForProductionJobMethod";
import { fragments as inkColorDetailsFragments } from "../../../InkColorDetails/graph";
import { fragments as productionJobCustomizationAreaListFragments } from "../../../OrderProductionJobCustomizationAreaList/graph";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../ProductionJobLineItemBreakdownTable/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../StyleSubstitutionLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CustomerArtworkPreview from "../CustomerArtworkPreview";
import CustomizationAreaArtworkList from "../../../CustomizationAreaArtworkList";
import CustomizationAreaBarcode from "../CustomizationAreaBarcode";
import CustomizationAreaNotes from "../CustomizationAreaNotes";
import DesignProofList from "../../../DesignProofList";
import Divider from "@mui/material/Divider";
import InkColorDetails from "../../../InkColorDetails";
import NamesAndNumbers from "../NamesAndNumbers";
import OrderProductionJobCustomizationAreaList from "../../../OrderProductionJobCustomizationAreaList";
import ProductionJobLineItemBreakdownTable from "../../../ProductionJobLineItemBreakdownTable";
import React, { useContext, useState } from "react";
import StyleSubstitutionLineItemBreakdown from "../../../StyleSubstitutionLineItemBreakdown";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ArtworkProjectionContext from "../../../../application/ArtworkProjectionContext";
import CustomizationAreaClaimDetails from "../../../CustomizationAreaClaimDetails";
import type { HOC } from "recompose";
import type { HighDefinitionDigitalStatus } from "../../types/HighDefinitionDigitalStatus";

type Props = {|
  +classes?: {|
    +artworkContainer: string,
    +artworkDetailsContainer: string,
    +artworkFilesContainer: string,
    +buttonHeaderContainer: string,
    +detailContainer: string,
    +image: string,
    +inkColorListItem: string,
    +leftContainer: string,
    +list: string,
    +methodIconContainer: string,
    +productList: string,
    +productListItem: string,
    +productsContainer: string,
    +productStyleWrapper: string,
    +proofsProductContainer: string,
    +rightContainer: string,
    +tableContainer: string,
    +titleText: string
  |},
  +isArtApp: boolean,
  +productionJob: {|
    +customizationAreas: $ReadOnlyArray<{|
      +artwork: $ReadOnlyArray<{|
        +asset: {|
          +url: {|
            +formatted: string
          |}
        |},
        +id: string,
        +originalFilename: string
      |}>,
      +id: string,
      +inkColors: $ReadOnlyArray<{|
        +id: string,
        +inkColor: ?{|
          +color: {|
            +formatted: string
          |},
          +id: string,
          +name: string
        |},
        +pantoneColor: ?{|
          +code: string,
          +color: {|
            +formatted: string
          |},
          +id: string
        |}
      |}>,
      +location: string,
      +method: string
    |}>,
    +design: $ReadOnlyArray<{|
      +backPreviewImageUrl: {
        +formatted: string
      },
      +frontPreviewImageUrl: {
        +formatted: string
      },
      +rushOrderTeesUrl: {
        +formatted: string
      }
    |}>,
    +filteredProofs: $ReadOnlyArray<{|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string,
      +originalFilename: string
    |}>,
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +id: string,
    +lineItemBreakdownByProduct: $ReadOnlyArray<{|
      +id: string,
      +product: {|
        +style: string
      |}
    |}>,
    +unclassifiedArtwork: $ReadOnlyArray<{|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string,
      +originalFilename: string
    |}>
  |},
  +shouldShowCustomerArtworkWithNotes: boolean,
  +shouldShowInkColors: boolean
|};

const styles = theme => ({
  artworkContainer: {
    padding: theme.spacing(1)
  },

  artworkDetailsContainer: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  buttonHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  proofsProductContainer: {
    display: "flex",
    padding: theme.spacing(1)
  },

  detailContainer: {
    padding: theme.spacing(1)
  },

  image: {
    height: 100
  },

  inkColorListItem: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },

  artworkFilesContainer: {
    width: "100%",
    marginRight: theme.spacing(2)
  },

  leftContainer: {
    width: "40%",
    marginRight: theme.spacing(2)
  },

  list: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  locationContainer: {
    padding: theme.spacing(1)
  },

  methodIconContainer: {
    display: "flex",
    alignItems: "center"
  },

  productStyleWrapper: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    minWidth: 100
  },

  productsContainer: {
    display: "flex"
  },

  productList: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  productListItem: {
    marginBottom: theme.spacing(2)
  },

  rightContainer: {
    width: "60%"
  },

  tableContainer: {
    marginTop: -23,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

  tooltip: {
    backgroundColor: "#ffffff",
    padding: 0,
    display: "flex",
    flexWrap: "nowrap",
    width: "700px"
  },

  popper: { opacity: 1 },

  zoomedImage: {
    width: "300px",
    height: "auto"
  },

  proofsTitleButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },

  titleText: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  flipArtwork: {
    transform: "rotate(180deg)"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobDetail"),
  withStyles(styles)
);

const customizationMethod = {
  DIRECT_TO_GARMENT: "Direct-to-garment",
  CUT_VINYL: "Cut Vinyl",
  EMBROIDERY: "Embroidery",
  HEAT_PRESS: "Heat Press",
  PRINTED_VINYL: "Printed Vinyl",
  SCREEN_PRINTING: "Screen Printing",
  SUBLIMATION: "Sublimation",
  TACKLE_TWILL: "Tackle Twill"
};

const ProductionJobDetail = ({
  classes,
  isArtApp,
  productionJob,
  shouldShowCustomerArtworkWithNotes,
  shouldShowInkColors
}) => {
  const [flipArtworkEnabled, setFlipArtworkEnabled] = useState(false);

  const { artworkProjectionEnabled, updateArtworkProjection } = useContext(
    ArtworkProjectionContext
  );

  const handleFlipArtworkEnable = () => {
    setFlipArtworkEnabled(true);
  };
  const handleFlipArtworkDisable = () => {
    setFlipArtworkEnabled(false);
  };

  const handleArtworkProjection = (e, artworkUrl) => {
    console.log(artworkUrl);
    if (!artworkProjectionEnabled) return;
    e.preventDefault();
    updateArtworkProjection(artworkUrl);
  };

  return (
    <div className={classes.detailContainer}>
      {productionJob.highDefinitionDigitalStatus !== "NOT_APPLICABLE" && (
        <div className={classes.locationContainer}>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              popper: classes.popper
            }}
            placement="right"
            disableFocusListener
            disableTouchListener
            title={
              <React.Fragment>
                {productionJob.boxLocationHistory &&
                  productionJob.boxLocationHistory.length > 0 && (
                    <React.Fragment>
                      <Typography variant="subtitle2">Goods:</Typography>
                      {productionJob.boxLocationHistory.map(
                        ({ id, createdAt, createdBy, location }) => (
                          <div key={id} className={classes.locationHistory}>
                            <Typography variant="caption">
                              {`${location} - ${
                                createdBy.name
                              } (${DateTime.fromISO(createdAt).toLocaleString(
                                DateTime.DATETIME_SHORT
                              )})`}
                            </Typography>
                          </div>
                        )
                      )}
                    </React.Fragment>
                  )}
                {productionJob.screenLocationHistory &&
                  productionJob.screenLocationHistory.length > 0 && (
                    <React.Fragment>
                      <Typography variant="subtitle2">Screens:</Typography>
                      {productionJob.screenLocationHistory.map(
                        ({ id, createdAt, createdBy, location }) => (
                          <div key={id} className={classes.locationHistory}>
                            <Typography variant="caption">
                              {`${location} - ${
                                createdBy.name
                              } (${DateTime.fromISO(createdAt).toLocaleString(
                                DateTime.DATETIME_SHORT
                              )})`}
                            </Typography>
                          </div>
                        )
                      )}
                    </React.Fragment>
                  )}
              </React.Fragment>
            }
          >
            <div className={classes.latestLocation}>
              {productionJob.latestBoxLocation && (
                <Typography variant="caption">{`G: ${productionJob.latestBoxLocation.location}`}</Typography>
              )}
              {productionJob.latestScreenLocation && (
                <Typography variant="caption">{`S: ${productionJob.latestScreenLocation.location}`}</Typography>
              )}
              {undefined !== productionJob.stockContainers &&
                productionJob.stockContainers.length > 0 && (
                  <React.Fragment>
                    <Divider light={true} className={classes.divider} />
                    <Typography
                      className={classes.productionJobLabel}
                      variant="body1"
                    >
                      Stock Containers ({productionJob.stockContainers.length})
                    </Typography>
                    {productionJob.stockContainers.map(
                      ({ latestLocation, id }, index) => (
                        <Typography
                          color="textSecondary"
                          key={id}
                          variant="subtitle2"
                        >
                          {index + 1} -{" "}
                          {latestLocation
                            ? latestLocation.location
                            : `Location
         Unknown`}
                        </Typography>
                      )
                    )}
                    <Divider light={true} className={classes.divider} />
                  </React.Fragment>
                )}
            </div>
          </Tooltip>
        </div>
      )}
      <div className={classes.proofsProductContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.proofsTitleButtonContainer}>
            <Typography variant="h6">Proofs</Typography>
            <Button
              variant="outlined"
              onMouseDown={handleFlipArtworkEnable}
              onTouchStart={handleFlipArtworkEnable}
              onMouseUp={handleFlipArtworkDisable}
              onMouseLeave={handleFlipArtworkDisable}
              onTouchEnd={handleFlipArtworkDisable}
            >
              Flip/Al revés
            </Button>
          </div>
          <DesignProofList
            flipArtwork={flipArtworkEnabled}
            proofs={filter(
              designProofListFragments.proofs,
              productionJob.filteredProofs
            )}
          />
        </div>
        <div className={classes.rightContainer}>
          {isArtApp ? (
            <OrderProductionJobCustomizationAreaList
              productionJobId={productionJob.id}
              customizationAreas={filter(
                productionJobCustomizationAreaListFragments.customizationAreas,
                productionJob.customizationAreas
              )}
              shouldShowInkColors={shouldShowInkColors}
              shouldShowCustomerArtworkWithNotes={
                shouldShowCustomerArtworkWithNotes
              }
            />
          ) : (
            <React.Fragment>
              <div className={classes.buttonHeaderContainer}>
                <Typography className={classes.titleText} variant="h6">
                  Artwork
                </Typography>
                {productionJob.design && (
                  <div>
                    <Tooltip
                      classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltip
                      }}
                      placement="left-end"
                      title={
                        <React.Fragment>
                          {productionJob.design.frontPreviewImageUrl && (
                            <img
                              className={classes.zoomedImage}
                              src={
                                productionJob.design.frontPreviewImageUrl
                                  .formatted
                              }
                              alt="Front Preview"
                            />
                          )}
                          {productionJob.design.backPreviewImageUrl && (
                            <img
                              className={`${classes.zoomedImage} ${
                                flipArtworkEnabled ? classes.flipArtwork : ""
                              }`}
                              src={
                                productionJob.design.backPreviewImageUrl
                                  .formatted
                              }
                              alt="Back Preview"
                            />
                          )}
                        </React.Fragment>
                      }
                    >
                      <Button
                        variant="outlined"
                        href={
                          productionJob.design &&
                          productionJob.design.rushOrderTeesUrl.formatted
                        }
                        disabled={!productionJob.design}
                        target="_blank"
                        rel="noopener"
                      >
                        View Design Studio
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
              {productionJob.customizationAreas.length > 0 ? (
                <ul className={classes.list} style={{ flexFlow: "wrap" }}>
                  {productionJob.customizationAreas.map(
                    (
                      {
                        artwork,
                        customerArtwork,
                        id,
                        inkColors,
                        location,
                        method,
                        notesCollection
                      },
                      index
                    ) => (
                      <React.Fragment key={id}>
                        <li style={{ width: "50%" }}>
                          <div className={classes.artworkDetailsContainer}>
                            <div className={classes.artworkFilesContainer}>
                              <Typography variant="subtitle2">
                                {`${location} (${index + 1}/${
                                  productionJob.customizationAreas.length
                                }) `}
                              </Typography>
                              <div className={classes.methodIconContainer}>
                                {getIconForProductionJobMethod(method, index)}
                                <Typography variant="subtitle2">
                                  {customizationMethod[method]}
                                </Typography>
                              </div>
                              <CustomizationAreaArtworkList
                                flipArtwork={flipArtworkEnabled}
                                proofs={filter(
                                  customizationAreaArtworkListFragments.proofs,
                                  artwork
                                )}
                                onClick={handleArtworkProjection}
                              />
                              <div>
                                {shouldShowCustomerArtworkWithNotes && (
                                  <div>
                                    <CustomizationAreaNotes
                                      notesCollection={notesCollection}
                                    />
                                    <CustomerArtworkPreview
                                      customerArtwork={customerArtwork}
                                    />
                                  </div>
                                )}
                                {shouldShowInkColors &&
                                  (inkColors.length === 0 ? (
                                    <span>No colors specified</span>
                                  ) : (
                                    <dl>
                                      <Typography
                                        component="dt"
                                        variant="subtitle2"
                                      >
                                        Ink Color(s):
                                      </Typography>
                                      <Typography
                                        component="dd"
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {inkColors.length === 0 ? (
                                          <span>No colors specified</span>
                                        ) : (
                                          <ul className={classes.list}>
                                            {inkColors.map(
                                              ({
                                                id,
                                                inkColor,
                                                pantoneColor
                                              }) => (
                                                <li
                                                  className={
                                                    classes.inkColorListItem
                                                  }
                                                  key={id}
                                                >
                                                  <InkColorDetails
                                                    inkColor={
                                                      inkColor
                                                        ? filter(
                                                            inkColorDetailsFragments.inkColor,
                                                            inkColor
                                                          )
                                                        : null
                                                    }
                                                    pantoneColor={
                                                      pantoneColor
                                                        ? filter(
                                                            inkColorDetailsFragments.pantoneColor,
                                                            pantoneColor
                                                          )
                                                        : null
                                                    }
                                                  />
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </Typography>
                                    </dl>
                                  ))}
                                {method === "DIRECT_TO_GARMENT" && (
                                  <CustomizationAreaBarcode
                                    customizationAreaId={id}
                                  />
                                )}
                                <CustomizationAreaClaimDetails
                                  customizationAreaId={id}
                                  notesCollection={notesCollection}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </React.Fragment>
                    )
                  )}
                </ul>
              ) : (
                <div>No Customization Areas</div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={classes.artworkContainer}>
        <Typography className={classes.titleText} variant="h6">
          Products
        </Typography>
        <ul className={classes.productList}>
          {productionJob.lineItemBreakdownByProduct.map((breakdown, index) => (
            <li key={index} className={classes.productListItem}>
              <div className={classes.productsContainer}>
                <div className={classes.tableContainer}>
                  <Typography variant="body1">
                    {breakdown.product.fullDisplayName}
                  </Typography>
                  {breakdown.product.isStyleSubstitution ? (
                    <StyleSubstitutionLineItemBreakdown
                      lineItems={filter(
                        styleSubstitutionLineItemBreakdownFragments.lineItems,
                        breakdown.lineItems
                      )}
                    />
                  ) : (
                    <ProductionJobLineItemBreakdownTable
                      lineItems={filter(
                        productionJobLineItemBreakdownTableFragments.lineItems,
                        breakdown.lineItems
                      )}
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
        <NamesAndNumbers productionJob={productionJob} />
      </div>
      {productionJob.unclassifiedArtwork &&
        productionJob.unclassifiedArtwork.length > 0 && (
          <div className={classes.proofsProductContainer}>
            <div className={classes.leftContainer}>
              <Typography className={classes.titleText} variant="h6">
                Unclassified Artwork
              </Typography>
              <DesignProofList
                proofs={filter(
                  designProofListFragments.proofs,
                  productionJob.unclassifiedArtwork
                )}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default enhancer(ProductionJobDetail);
